<template>
  <div class="indexList Homenewslistindex">
    <div class="haveContent" id="haveContent" v-if="listAllData">
      <mescroll-vue
        ref="mescroll"
        :up="mescrollUp"
        @init="mescrollInit"
        class="gundong"
        style="height: 100%"
      >
        <!-- 轮播图 -->
        <!-- <div class="swiperBox" v-if="listAllData.HD && listAllData.HD.length">
          <div class="swiper-container" :class="'myswiper' + myIndex">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in listAllData.HD" :key="index">
                <div class="thumbImg">
                  <div class="linearback"></div>
                  <p class="thumbImgTitle">{{ item.mainContributionTitle }}</p>
                  <img
                    v-if="JSON.parse(item.contributionCover)[0]"
                    :src="JSON.parse(item.contributionCover)[0]"
                    alt
                  />
                  <img v-else src="../../assets/images/default.png" alt />
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>-->

        <div
          class="swiperBox"
          v-if="listAllData.HD && listAllData.HD.length"
          @touchstart.stop
          @touchmove.stop
          @touchend.stop
        >
          <van-swipe :autoplay="3000" class="swiper-container">
            <van-swipe-item
              v-for="(item, index) in listAllData.HD"
              :key="index"
              @click="goToArticle(item)"
            >
              <div class="thumbImg">
                <div class="linearback"></div>
                <p class="thumbImgTitle">{{ item.mainContributionTitle }}</p>
                <img
                  v-if="JSON.parse(item.contributionCover)[0]"
                  :src="JSON.parse(item.contributionCover)[0]"
                  v-lazy="listAllData.HD"
                  alt
                />
                <img v-else src="../../assets/images/default.png" alt />
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>

        <div
          class="swiperBox swiperBoxTwo"
          v-if="middleNav&&middleNav.length"
          @touchstart.stop
          @touchmove.stop
          @touchend.stop
        >
          <van-swipe :show-indicators="false" v-if="swiperShow" class="swiper-container">
            <van-swipe-item v-for="(itemOut, index) in Math.ceil(middleNav.length/5)" :key="index">
              <div class="class_body">
                <div
                  class="class_list"
                  v-for="(item, indexin) in middleNav.slice(index*5, (index+1)*5)"
                  :key="indexin"
                  @click="middleNavClick(item)"
                >
                  <img v-if="item.navigationIcon" :src="item.navigationIcon" alt />
                  <img v-else src="../../assets/images/amedia.png" alt />
                  <span class="class_content">{{ item.navigationName }}</span>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>

        <div class="data_all" v-if="statisticsNum">
          <img src="../../assets/images/numall.png" alt />
          <div class="sum_body">
            <div class="sum_list" v-for="(item, index) in statisticsNum" :key="index">
              <span class="sum_content_two">{{ item.name }}</span>
              <span class="sum_content">{{ item.count }}</span>
            </div>
          </div>
        </div>
        <div v-if="progressivedata">
          <div class="wenming_img">
            <img src="../../assets/images/astart.png" alt />
            <span>文明实践进行时</span>
          </div>
          <div class="wenming_body">
            <div
              class="wenming_wrapper"
              v-for="(item, index) in progressivedata"
              :key="index"
              @click="enterDetails(item)"
            >
              <div class="wenming_list">
                <img v-if="index == 0" src="../../assets/images/weatherBc1.jpg" alt />
                <img v-if="index == 1" src="../../assets/images/weatherBc2.png" alt />
                <span class="wenming_content ellipsis-m">{{ item.name }}</span>
                <span class="wenming_content_two">{{ item.regionName }}</span>
              </div>
              <img class="zhaomu_img" src="../../assets/images/zhaomuing.png" alt />
            </div>
          </div>
        </div>
        <!-- 置顶 -->
        <!-- <roofPlacement
          :listcontent="listAllData.stickList"
          v-if="listAllData&&listAllData.stickList"
        ></roofPlacement>-->
        <!-- 新闻列表 -->
        <div class="newsBox">
          <div class="borderBox"></div>
          <newslist :listcontent="newsDataList"></newslist>
        </div>
      </mescroll-vue>
    </div>
    <loading :iscomplete="listAllData ? false : true"></loading>
  </div>
</template>

<script>
// import Swiper from "swiper";
// import "../../assets/swiper.min.css";
import newslist from "./NewsList.vue"; //新闻列表
import loading from "./loading.vue"; //加载动画
import { Toast } from 'vant';
//import roofPlacement from './roofPlacement.vue'
import MescrollVue from "mescroll.js/mescroll.vue"; //上拉加载，下拉刷新
import Homeapi from "@/api/home";
import { Lazyload } from 'vant';
export default {
  props: {
    currentdisplay: {
      type: Number,
      default: () => 1, //必须是一个函数
      required: false, //不是必填项
    },
    myIndex: {
      type: Number,
      default: () => 1, //必须是一个函数
      required: false, //不是必填项
    },
    datalink: {
      type: Object,
      default: () => { }, //必须是一个函数
      required: true, //不是必填项
    },
    statistics: {
      type: Array,
    },
    middleNav: {
      type: Array,
    },
    progressivedata: {
      type: Array,
    },
    appName: {
      //是否显示
      type: String,
      default: () => "", //必须是一个函数
      required: true,
    },
  },
  data() {
    return {
      statisticsNum: this.statistics,
      listAllData: [], //页面数据
      newsDataList: [], //新闻列表
      swiperShow: false,
      swiperContent: null, //轮播图
      mescroll: null, // mescroll实例对象
      mescrollUp: {
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          rows: 10, // 每页数据的数量
        },
        htmlLoading:
          '<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">加载中..</p>',
        htmlNodata: '<p class="upwarp-nodata">-- 到底啦 --</p>',
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        toTop: {
          src: require("../../assets/mescroll/mescroll-totop.png"), // 回到顶部按钮的图片路径,支持网络图
        },
        auto: true,
        lazyLoad: {
          use: true, // 是否开启懒加载,默认false
        },
      },
    };
  },
  mounted() {
    console.log('123', this.progressivedata);
    // if (this.middleNav.length) { //如果为H5打包 则里面没有积分商城 需自行去掉*-+

    //   // console.log('111', this.middleNav)
    //   let array = this.middleNav
    //   for (let i = 0; i < array.length; i++) {
    //     if (array[i].navigationSign == 'JFSC') {
    //       console.log('egyggygyu', array[i]);
    //       array.splice(i, 1)
    //     }
    //   }
    //   this.middleNav = array
    //   // if(.navigationSign === "JFSC")
    // }
    setTimeout(() => {
      this.swiperShow = true
    }, 1000);

  },
  components: {
    newslist, //新闻列表
    loading,
    MescrollVue,
    // roofPlacement //置顶
  },
  watch: {

    // 如果 `question` 发生改变，这个函数就会运行
    currentdisplay: {
      handler: function (newData) {
        if (!this.listAllData) {
          if (newData == this.myIndex) {
            // this.indexListDataObain();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 点击轮播前往文章详情
    goToArticle(val) {
      var link;
      if (val.contributionOtherUrl) {
        link = val.contributionOtherUrl; //链接
      } else if (val.contributionType == "IMAGE_TYPE") {
        link = window.location.href.split("#/")[0] + "#/tabloid?id=" + val.id; //链接
        // return false;
      } else {
        link = window.location.href.split("#/")[0] + "#/detailsTwo?id=" + val.id; //链接
      }
      window.location.href = link;
    },
    datastatistics() {
      // 指尖东至不需要数据统计
      let that = this
      if (that.appName == "指尖东至") {
        that.statisticsNum = null;
        return false;
      }
      let params = {
        appName: this.appName
      }
      Homeapi.Homestaticdata(params).then(res => {
        if (res.success) {
          this.statisticsNum = res.data
        } else {
          Toast.fail(res.message)
        }

      })
    },
    //文明实践进行时点击
    enterDetails(item) {
      if (this.isToken()) {
        var link =
          window.location.href.split("#/")[0] +
          "#/voluntaryRecruitmentDetail?id=" +
          item.id;
        window.location.href = link;
      }
    },
    // 点击中间banner
    middleNavClick(data) {
      let link;
      if (data.navigationUrl) {
        link = data.navigationUrl
      } else if (data.navigationSign === "JFSC") {//积分商城跳转页面 首页导航
        let en = window.navigator.userAgent
        let isWechat = true
        if (en.search(/MicroMessenger/i) > -1) {
          isWechat = true
        }
        const url = window.location.origin + '/#'
        // console.log("是否在微信中：" + isWechat)
        if (window.localStorage.personalInformation && window.localStorage.personalInformation != "undefined") {
          if (isWechat) {
            window.location.href = `${this.scoreShopUrl}?env=browser&url=${encodeURIComponent(url)}&userCivilization=${localStorage.personalInformation}`
          } else {
            window.uni.navigateTo({ url: '/pages/home/jfscH5/jfscH5?userCivilization=' + window.localStorage.personalInformation })
          }
        } else {
          if (isWechat) {
            window.location.href = `${this.scoreShopUrl}?env=browser&url=${encodeURIComponent(url)}&userCivilization=`
          } else {
            window.uni.navigateTo({ url: '/pages/home/jfscH5/jfscH5?userCivilization=' + '' })
          }
        }
        return
      }
      else if (data.navigationSign === "duiwujianshe") {
        //队伍建设
        //  alert(link)
        link = window.location.href.split("#/")[0] + "#/army"; //链接
      } else if (data.navigationSign === "baoliao") {
        //群众爆料
        link = window.location.href.split("#/")[0] + "#/interaction"; //链接
      } else if (data.navigationSign === "huodongzhaomu") {
        //活动招募
        link = window.location.href.split("#/")[0] + "#/voluntaryRecruitment"; //链接
      } else if (data.navigationSign === "XinYuanDaTing") {
        //心愿大厅
        link = window.location.href.split("#/")[0] + "#/demand"; //链接
      } else if (data.navigationSign === "meitibaodao") {
        //媒体报道
        link =
          window.location.href.split("#/")[0] +
          "#/Mediareport?columnId=" +
          data.columnId; //链接
      } else if (data.navigationSign === "zhiyuandianxing") {
        //志愿典型
        link =
          window.location.href.split("#/")[0] +
          "#/wish?columnId=" +
          data.columnId; //链接
      } else if (data.navigationSign === "JianDuJuBao") {
        link = "http://220.178.228.152:6012/dist/#/pages/report/report"
      } else {
        let url = data.navigationInterface;
        let index = url.lastIndexOf("/");
        let str = url.substring(index + 1, url.length);
        if (str === 'getColumnContribution') {
          // 跳转无导航文章
          link =
            window.location.href.split("#/")[0] +
            "#/articleNoNav?datalink=" +
            data.navigationInterface + "&columnId=" + data.columnId + "&name=" + data.navigationName //链接
          this.$router.push({ path: '/articleNoNav', query: { columnId: data.columnId, datalink: data.navigationInterface, name: data.navigationName } });
          return
        } else {
          // 跳转有导航文章
          link =
            window.location.href.split("#/")[0] +
            "#/articleWithNav?columnId=" +
            data.columnId + "&datalink=" + data.navigationInterface + "&name=" + data.navigationName; //链接
          this.$router.push({ path: '/articleWithNav', query: { columnId: data.columnId, datalink: data.navigationInterface, name: data.navigationName } });
          return
        }

      }
      window.location.href = link;
    },
    // 头部新闻轮播图
    // InitializationSwiper() {
    //   if (this.swiperContent) {
    //     this.swiperContent.destroy();
    //   }
    //   var that = this;
    //   this.swiperContent = new Swiper(".myswiper" + this.myIndex, {
    //     direction: "horizontal", // 垂直切换选项
    //     loop: true, // 循环模式选项
    //     touchMoveStopPropagation: true, //阻止事件冒泡

    //     autoplay: {
    //       delay: 3000,
    //       stopOnLastSlide: false,
    //       disableOnInteraction: false,
    //     },

    //     // 如果需要分页器
    //     pagination: {
    //       el: ".swiper-pagination",
    //     },

    //     on: {
    //       click: function () {
    //         var x;
    //         if (this.clickedIndex == that.listAllData.HD.length + 1) {
    //           x = that.listAllData.HD[0];
    //         } else if (this.clickedIndex == 0) {
    //           x = that.listAllData.HD[that.listAllData.HD.length - 1];
    //         } else {
    //           x = that.listAllData.HD[this.clickedIndex - 1];
    //         }

    //         // console.log(x);
    //         var link;
    //         if (x.contributionOtherUrl) {
    //           link = x.contributionOtherUrl; //链接
    //         } else if (x.contributionType == "IMAGE_TYPE") {
    //           link = window.location.href.split("#/")[0] + "#/tabloid?id=" + x.id; //链接
    //           // return false;
    //         } else {
    //           link = window.location.href.split("#/")[0] + "#/detailsTwo?id=" + x.id; //链接
    //         }
    //         window.location.href = link;
    //       },
    //     },
    //   });
    // },
    // 中部导航轮播图
    // middleNavSwiper() {
    //   var myTwoSwiper = new Swiper(".swiper-hei" + this.myIndex, {
    //     direction: "horizontal",
    //     touchMoveStopPropagation: true, //阻止事件冒泡
    //   });
    // },
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // console.log(this.datalink);
      if (this.statisticsNum || this.progressivedata) {
        this.$emit("refresh");
      }
      // this.datastatistics()
      let params = {
        page: page.num,
        rows: page.rows,
        appName: this.appName,
        columnId: this.datalink.columnId ? this.datalink.columnId : 0,
      };
      let apilink = this.datalink.navigationInterface;
      Homeapi.getContribution(params, apilink)
        .then((resp) => {
          if (resp.success) {
            if (page.num === 1) {
              this.listAllData = resp.data;
              this.newsDataList = [];
              if (this.listAllData.HD) {
                // this.$nextTick(() => {
                //   this.InitializationSwiper();
                //   this.middleNavSwiper();
                // });
              }
            }
            if (resp.data.pageList) {
              this.newsDataList = this.newsDataList.concat(
                resp.data.pageList.items
              );
            }
          } else {
            // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
            mescroll.endErr();
          }
          this.$nextTick(() => {
            if (resp.data.pageList.items) {
              mescroll.endSuccess(resp.data.pageList.items.length);
            } else {
              mescroll.endSuccess(0);
            }
          });
        })
        .catch(() => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          mescroll.endErr();
        });
    }
  },
};
</script>

<style>
.Homenewslistindex .swiper-pagination-bullet {
  background: #fff;
  opacity: 1;
}
.Homenewslistindex
  .swiper-container-horizontal
  .swiper-pagination-bullet-active {
  background: #1377e2;
}
.Homenewslistindex .mescroll-empty {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20vw;
}
</style>

<style scoped>
.indexList {
  width: 100vw;
  height: 100%;
  position: relative;
}
.indexList .swiper-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
/* .indexList .swiper-hei {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
} */
.ellipsis-m {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.swiperBox {
  width: 100%;
  padding-bottom: 60%;
  position: relative;
}
.swiperBoxTwo {
  width: 100%;
  padding-bottom: 20%;
  margin: 10px 0;
  position: relative;
}
.outLinkerContent {
  width: 100%;
  padding-bottom: 20%;
  position: relative;
  /* margin-top: 15px; */
}
.outLinkerContentBox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.outLinkerPer {
  width: 20%;
  height: 80%;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.outLinkerPerPer {
  width: 100%;
  height: 56%;
}
.outLinkerPerPer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.outLinkerPerTitle {
  width: 100%;
  /* line-height: 25px; */
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.class_body {
  display: flex;
  flex-wrap: wrap;
  margin: 0.21rem 0.28rem;
  /* justify-content: space-between; */
}
.class_list {
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 2vw; */
  font-size: 0.13rem;
  width: 20%;
}
.class_content {
  /* width: 28vw; */
  /* text-align: center; */
  /* margin: 2vw 0; */
  font-size: 0.26rem;
  /* font-weight: bold; */
}
.class_btn {
  width: 16vw;
  background: #f61729;
  color: #fff;
  padding: 1px 5px;
  border-radius: 7px;
}
.class_list img {
  width: 9vw;
}
.data_all {
  height: 2.28rem;
  margin: 0 0.28rem 4vw;
  background: #ffffff;
  box-shadow: 1px 4px 3px 0px rgba(114, 44, 36, 0.05);
  border-radius: 10px;
}
.data_all img {
  width: 100%;
}
.sum_body {
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  margin-top: -1vw;
  padding: 0.2rem;
}
.sum_list {
  width: 20vw;
  height: 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 2vw 0;
  border-right: 1px solid #bfbfbf;
  padding: 0 5px;
}
.sum_list:last-child {
  border-right: 0;
}

.sum_Bigcontent {
  margin: 1vw 0;
  font-size: 0.21rem;
  font-weight: bold;
}

@media screen and (max-width: 414px) {
  .sum_content_two {
    font-size: 0.27rem;
    color: #939393;
  }
  .sum_content {
    margin: 1vw 0;
    font-size: 0.35rem;
    font-weight: bold;
  }
}

@media screen and (min-width: 414px) {
  .sum_content_two {
    font-size: 0.22rem;
    color: #939393;
  }
  .sum_content {
    margin: 1vw 0;
    font-size: 0.235rem;
    font-weight: bold;
  }
}
.wenming_img {
  display: flex;
  align-items: center;
  font-size: 0.31rem;
  font-weight: bold;
  padding: 2vw 3vw;
}
.wenming_img img {
  width: 6vw;
  margin-right: 5px;
}
.wenming_body {
  display: flex;
  margin: 0.21rem 0.28rem;
  justify-content: space-between;
}
.wenming_wrapper {
  position: relative;
  width: 43vw;
}
.wenming_list {
  width: 43vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vw;
  border: 1px solid #dcdcdc;
}
.zhaomu_img {
  width: 17vw;
  position: absolute;
  top: 0;
}
.wenming_list img {
  width: 100%;
}
.wenming_content {
  /* width: 28vw; */
  /* text-align: center; */
  margin: 0.1rem auto;
  font-size: 0.3rem;
  font-weight: bold;
  width: 93%;
}
.wenming_content_two {
  margin: 0.1rem 0;
  font-size: 0.22rem;
  padding-left: 0.2rem;
  color: #939393;
  width: 100%;
}
.indexList .haveContent {
  width: 100%;
  height: 100%;
}
.indexList .thumbImg {
  width: 100%;
  height: 100%;
  position: relative;
}
@media screen and (max-width: 414px) {
  .indexList .thumbImg .thumbImgTitle {
    width: 80%;
    position: absolute;
    left: 10%;
    bottom: 0.6rem;
    color: #fff;
    text-shadow: 0.2em 0.2em 0.2em #000;
    text-align: center;
    font-size: 0.32rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media screen and (min-width: 415px) {
  .indexList .thumbImg .thumbImgTitle {
    width: 80%;
    position: absolute;
    left: 10%;
    bottom: 0.6rem;
    color: #fff;
    text-shadow: 0.2em 0.2em 0.2em #000;
    text-align: center;
    font-size: 0.2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.indexList .thumbImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.indexList .titleBunner {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 0px;
  color: #333;
  font-size: 0.28rem;
  font-weight: 700;
  padding: 0px 10px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #e6e6e6;
  padding-top: 5px;
}
.titleIndex {
  position: absolute;
  bottom: 0;
  right: 5px;
  font-size: 0.24rem;
  color: #ababab;
}
.titleIndex span {
  color: red;
  font-weight: 100;
}
.borderBox {
  /* border:  solid#e6e6e6; */
  /* width: 100%; */
  /* margin-top: -50px; */
}
/* .newsBox{
        margin-top: 18px;
    } */
.outSwiper {
  /* margin-top: 5px; */
  margin: 10px auto;
}
.my-swipe .van-swipe-item {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.imgBox {
  /* margin: 0 1px; */
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.imgBox img {
  width: 35px;
  height: 35px;
}
.outerTitle {
  font-size: 14px;
}
.ellipsis-m {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.indexList .thumbImg {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  width: 100%;
  height: 100%;
  position: relative;
}
.indexList .thumbImg .linearback {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}
</style>